import { RefreshCcw } from "lucide-react";
import { Button } from "@/components/ui/button";

export function NotFoundView({ title }: any) {
  return (
    <div className="w-full h-full p-20 flex flex-grow flex-col items-center justify-center">
      <div className="text-2xl">
        {title ?? "Sorry this content is not available."}
      </div>
      <br />
      <Button
        className="uppercase bg-[#FF7900]"
        onClick={() => window.location.reload()}
      >
        Reload <RefreshCcw className="ml-1 h-5 w-5" />
      </Button>
    </div>
  );
}
