import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { StacksApiContext } from "../context";
import { NetworkState } from "../state";

import { useApi } from "./useApi";
import { usePagination, PaginationOptions } from "./usePagination";

export type TokenProps = {
  orderBy?: string;
} & PaginationOptions;

export function useTokens(props?: TokenProps) {
  const { opnetApi } = useContext(StacksApiContext)!;
  const network = useRecoilValue(NetworkState);
  const { page, pageSize, ...paginationFns } = usePagination(props);

  const { data, loading, error } = useApi(
    opnetApi.getTokenList.bind(opnetApi),
    [
      network,
      props?.orderBy,
      {
        limit: pageSize,
        cursor: page - 1,
      },
    ]
  );

  return {
    tokens: data?.results || [],
    total: data?.total ?? 0,
    hasNextPage: data?.hasNextPage ?? true,
    loading,
    error,
    page,
    pageSize,
    ...paginationFns,
  };
}
