import { useState } from "react";

import { Card } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { PageContentHeader } from "@/components/PageContentHeader";
import { TokenList } from "@/components/TokenList";

export function TokensPage() {
  const [orderBy, setOrderBy] = useState<string>("deployedAt");
  return (
    <div className="flex container p-4 space-y-4 flex-col">
      <PageContentHeader
        title="Tokens"
        accessory={
          <Select value={orderBy} onValueChange={setOrderBy}>
            <SelectTrigger className="w-[180px]">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Order By</SelectLabel>
                <SelectItem value="deployedAt">Deploy Date</SelectItem>
                <SelectItem value="op20Metadata.totalSupply">
                  Total Supply
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        }
      />
      <Card className="overflow-hidden">
        <TokenList paginate={true} compact={false} orderBy={orderBy} />
      </Card>
    </div>
  );
}
