import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { useBlocks } from "@/hooks";
import { BlockRow } from "@/components/BlockRow";
import { DataTablePagination } from "@/components/DataTablePagination";
import { SkeletonRow } from "./SkeletonRow";

export type BlockListProps = {
  paginate?: boolean;
  compact?: boolean;
};

export function BlockList({ paginate, compact }: BlockListProps) {
  const {
    blocks,
    total,
    loading,
    nextPage,
    prevPage,
    goToPage,
    changePageSize,
    page,
    pageSize,
    hasNextPage,
  } = useBlocks();
  return (
    <div>
      <Table key="table">
        <TableHeader>
          <TableRow>
            <TableHead>Block Number</TableHead>
            <TableHead>Block Hash</TableHead>
            <TableHead className="hidden lg:table-cell">Tx Count</TableHead>
            {!compact && (
              <TableHead className="hidden md:table-cell">
                Total OpNet Fees
              </TableHead>
            )}
            {!compact && (
              <TableHead className="hidden md:table-cell">Gas Used</TableHead>
            )}
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {loading
            ? Array.from({ length: 10 }).map((_, i) => (
                <SkeletonRow
                  icon={true}
                  title={true}
                  subtitle={false}
                  date={true}
                  otherCols={compact ? 1 : 4}
                  key={i}
                />
              ))
            : null}
          {!loading &&
            blocks.map((block: any) => (
              <BlockRow block={block} compact={compact} key={block?.height} />
            ))}
        </TableBody>
      </Table>
      {paginate && (
        <div
          className="border-t bg-muted/50 font-medium px-3 py-2"
          key="pagination"
        >
          <DataTablePagination
            loading={loading}
            total={total}
            page={page}
            pageSize={pageSize}
            setPageSize={changePageSize}
            setPage={goToPage}
            prevPage={prevPage}
            nextPage={nextPage}
            hasNextPage={hasNextPage}
          />
        </div>
      )}
    </div>
  );
}
