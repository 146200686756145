import { useTransactionEvents } from "@/hooks";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody } from "@/components/ui/table";
import { EventRow } from "@/components/EventRow";
import { DataTablePagination } from "@/components/DataTablePagination";

export function TransactionEventsCard({ transaction }: { transaction: any }) {
  const {
    events,
    loading,
    goToPage,
    page,
    pageSize,
    changePageSize,
    prevPage,
    nextPage,
    hasNextPage,
  } = useTransactionEvents({ transactionId: transaction.id });

  return (
    <Card className="overflow-hidden">
      <CardHeader>
        <CardTitle>Events</CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        {!loading &&
          events.map((event: any, i: number) => (
            <EventRow event={event} key={page + i} />
          ))}

        <div className="border-t bg-muted/50 font-medium px-3 py-2">
          <DataTablePagination
            loading={loading}
            page={page}
            pageSize={pageSize}
            setPageSize={changePageSize}
            setPage={goToPage}
            prevPage={prevPage}
            nextPage={nextPage}
            hasNextPage={hasNextPage}
          />
        </div>
      </CardContent>
    </Card>
  );
}
