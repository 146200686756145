import { Network, networks } from "@btc-vision/bitcoin";

export function getBTCNetwork(network: string): Network {
  switch (network) {
    case "mainnet":
    case "fractal_mainnet":
      return networks.bitcoin;
    case "testnet":
    case "fractal_testnet":
      return networks.testnet;
    case "regtest":
      return networks.regtest;
    default:
      throw new Error(`Unknown network: ${network}`);
  }
}
