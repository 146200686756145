import { formatNumber } from "./formatNumber";
import { satsToBTC } from "./satsToBTC";

const simpleNameMap: Record<string, string> = {
  mint: "Mint",
  transfer: "Transfer",
  burn: "Burn",
  transferFrom: "Transfer",
  approve: "Approve",
  createPool: "Create Pool",
  initialize: "Initialize",
  swap: "Swap",
  skim: "Skim",
  sync: "Sync",
  addLiquidity: "Add Liquidity",
  removeLiquidity: "Remove Liquidity",
  swapExactTokensForTokensSupportingFeeOnTransferTokens: "Swap",
  airdrop: "Airdrop",
  stake: "Stake",
  unstake: "Unstake",
  claim: "Claim",
  requestWithdrawal: "Request Withdrawal",
};

export function createTitleForTransaction(
  transaction: Record<any, any>,
  address?: string,
  isSender?: boolean
): string {
  switch (transaction.type) {
    case "interaction":
      const fn = transaction.decodedMethodArgs ?? {};
      return simpleNameMap[fn?.name] ?? fn?.name ?? "Function Call";
    case "coinbase":
      return "Coinbase";
    case "deployment":
      return "Deployed Contract";
    case "generic":
      let sender = isSender ?? false;
      let value = 0n;
      if (address) {
        transaction.inputs?.forEach((input: any) => {
          if (input.address === address) {
            sender = true;
            value += BigInt(input.value);
          }
        });
        // resolve?
        value = BigInt(transaction.inputValue);
      } else {
        value = BigInt(transaction.inputValue);
      }
      return `${sender || !address ? "Sent" : "Received"} ${satsToBTC(BigInt(value))} BTC`;
    case "wrap":
      const wrap = satsToBTC(BigInt(transaction.depositAmount));
      return `Wrapped ${formatNumber(wrap)} BTC`;
    case "unwrap":
      const unwrap = satsToBTC(BigInt(transaction.unwrapAmount));
      return `Unwrapped ${formatNumber(unwrap)} BTC`;
    default:
      return "Unknown Transaction";
  }
}

// mint / transfer
