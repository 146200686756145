import React, { useMemo } from "react";
import { Blocks, DollarSign, BarChart, LineChart } from "lucide-react";

import { MetricCard } from "@/components/MetricCard";
import { DataPreviewCard } from "@/components/DataPreviewCard";
import { useBlocks, useCoinGeckoPrice, useGasStats } from "@/hooks";
import { formatDateToNow } from "@/lib";
import { BlockList } from "@/components/BlockList";
import { TransactionList } from "@/components/TransactionList";
import { Separator } from "@radix-ui/react-separator";

export default function App() {
  const { data, loading: l1 } = useCoinGeckoPrice();
  const { blocks, loading: l2 } = useBlocks({ initialPageSize: 1 });
  const { stats, loading: l3 } = useGasStats();
  const loading = l1 || l2 || l3;

  return (
    <div className="flex container p-4 space-y-4 flex-col">
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
        <MetricCard
          loading={loading}
          title="BTC Price"
          icon={DollarSign}
          subtitle={`${
            data?.usd_24h_change > 0 ? "+" : ""
          }${data?.usd_24h_change?.toFixed(2)}% Last 24 Hours`}
        >
          ${data?.usd?.toLocaleString("en-US")}
        </MetricCard>
        <MetricCard loading={loading} title="BTC Market Cap" icon={BarChart}>
          ${Number(data?.usd_market_cap?.toFixed(0)).toLocaleString("en-US")}
        </MetricCard>
        <MetricCard
          loading={loading}
          title="Latest Block Gas (SATS)"
          icon={LineChart}
        >
          <div className="flex flex-row grow items-center text-xs justify-between">
            <div className="flex flex-col w-20">
              <div className="font-medium uppercase text-xs">Low</div>
              <div className="text-lg">
                {Number(blocks[0]?.gasPercentiles?.p50 ?? 0).toFixed(2)}
              </div>
            </div>
            <Separator orientation="vertical" />
            <div className="flex flex-col w-20">
              <div className="font-medium uppercase text-xs">Medium</div>
              <div className="text-lg">
                {Number(blocks[0]?.gasPercentiles?.p90 ?? 0).toFixed(2)}
              </div>
            </div>
            <Separator orientation="vertical" />
            <div className="flex flex-col w-20">
              <div className="font-medium uppercase text-xs">High</div>
              <div className="text-lg">
                {Number(blocks[0]?.gasPercentiles?.p95 ?? 0).toFixed(2)}
              </div>
            </div>
          </div>
        </MetricCard>
        <MetricCard
          loading={loading}
          title="Latest Block"
          icon={Blocks}
          subtitle={`${blocks?.[0]?.txCount} Transactions • ${formatDateToNow(
            blocks?.[0]?.time
          )}`}
        >
          #{Number(blocks?.[0]?.height).toLocaleString("en-US")}
        </MetricCard>
      </div>
      <div className="flex grow flex-col xl:flex-row gap-4">
        <div className="xl:w-1/2">
          <DataPreviewCard
            title="Latest Transactions"
            actionTitle="View All Transactions"
            href={"/transactions"}
          >
            <TransactionList compact={true} />
          </DataPreviewCard>
        </div>
        <div className="xl:w-1/2">
          <DataPreviewCard
            title="Latest Blocks"
            actionTitle="View All Blocks"
            href={"/blocks"}
          >
            <BlockList compact={true} />
          </DataPreviewCard>
        </div>
      </div>
    </div>
  );
}
