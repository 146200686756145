import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { useTokens } from "@/hooks";
import { TokenRow } from "@/components/TokenRow";
import { DataTablePagination } from "@/components/DataTablePagination";
import { SkeletonRow } from "./SkeletonRow";

export type TokenListProps = {
  paginate?: boolean;
  compact?: boolean;
  orderBy?: string;
};

export function TokenList({ paginate, compact, orderBy }: TokenListProps) {
  const {
    tokens,
    total,
    loading,
    goToPage,
    page,
    pageSize,
    changePageSize,
    prevPage,
    nextPage,
    hasNextPage,
  } = useTokens({ orderBy });
  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Token Info</TableHead>
            <TableHead>Contract Address</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {loading
            ? Array.from({ length: 10 }).map((_, i) => (
                <SkeletonRow
                  icon={true}
                  title={true}
                  subtitle={true}
                  date={false}
                  otherCols={1}
                  key={i}
                />
              ))
            : null}
          {!loading &&
            tokens.map((token: any) => (
              <TokenRow
                token={token}
                compact={compact}
                key={token?.contractAddres}
              />
            ))}
        </TableBody>
      </Table>
      {paginate && (
        <div className="border-t bg-muted/50 font-medium px-3 py-2">
          <DataTablePagination
            loading={loading}
            total={total}
            page={page}
            pageSize={pageSize}
            setPageSize={changePageSize}
            setPage={goToPage}
            prevPage={prevPage}
            nextPage={nextPage}
            hasNextPage={hasNextPage}
          />
        </div>
      )}
    </div>
  );
}
