import { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Blocks,
  Receipt,
  PersonStanding,
  Code,
  LucideIcon,
  Coins,
} from "lucide-react";

import { Button } from "./ui/button";
import { truncateInMiddle } from "@/lib/truncateInMiddle";

export type SearchItemProps = {
  entity: any;
};

export function SearchItem({ entity }: SearchItemProps) {
  const title = useMemo(() => {
    if (entity.type === "block") {
      return `#${Number(entity.height).toLocaleString("en-US")}`;
    } else if (entity.type === "wallet") {
      return truncateInMiddle(entity?.address || "", 10);
    } else {
      return truncateInMiddle(entity.idOrHash || "", 10);
    }
  }, [entity]);
  const Icon: LucideIcon = useMemo(() => {
    if (entity.type === "block") {
      return Blocks;
    } else if (entity.type === "wallet") {
      return PersonStanding;
    } else {
      return Receipt;
    }
  }, [entity]);
  const href = useMemo(() => {
    switch (entity.type) {
      case "block":
        return `/blocks/${entity.height}`;
      case "wallet":
        return `/accounts/${entity.address}`;
      case "transaction":
      default:
        return `/transactions/${entity.idOrHash}`;
    }
  }, [entity]);
  return (
    <div className="bg-white text-sm p-4 flex flex-row">
      <div className="p-2 bg-black rounded-lg inline-block">
        <Icon color="#FFF" className="h-5 w-5" />
      </div>
      <div className="ml-4 flex flex-col items-start justify-center">
        <Button
          className="font-bold text-sm p-0 h-auto text-primary"
          variant="link"
          key="title"
          asChild
        >
          <Link to={href}>{title}</Link>
        </Button>
      </div>
    </div>
  );
}
