import { Contract, IndexedBlock, IndexedTransaction } from "opscan-core";

const BASE_URL = "https://api.opscan.org/v1";

interface PaginationOptions {
  cursor?: number;
  limit?: number;
}

interface PaginatedResponse<T> {
  results: T[];
  hasNextPage: boolean;
  total: number;
  cursor?: string;
}

export default class OpnetApiClient {
  public static instance = new OpnetApiClient();

  async getBlockList(
    network: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<any>> {
    const url = new URL(BASE_URL + "/" + network + "/blocks");
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch block list");
    }
    return response.json();
  }

  async getBlockByHeight(network: string, height: bigint): Promise<any> {
    const response = await fetch(
      `${BASE_URL}/${network}/blocks/${height.toString()}`
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch block by height: ${height}`);
    }
    return response.json();
  }

  async getBlockByHash(network: string, hash: string): Promise<any> {
    const response = await fetch(
      `${BASE_URL}/${network}/blocks/${hash.toString()}`
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch block by hash: ${hash}`);
    }
    return response.json();
  }

  async getBlockTransactions(
    network: string,
    height: bigint,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<any>> {
    const url = new URL(
      `${BASE_URL}/${network}/blocks/${height.toString()}/transactions`
    );
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error(`Failed to fetch transactions for block: ${height}`);
    }
    return response.json();
  }

  async getTransactionList(
    network: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<any>> {
    const url = new URL(`${BASE_URL}/${network}/transactions`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch transaction list");
    }
    return response.json();
  }

  async getTransactionById(
    network: string,
    id: string,
    extendEvents?: boolean
  ): Promise<any> {
    const url = new URL(`${BASE_URL}/${network}/transactions/${id}`);
    if (extendEvents !== undefined) {
      url.searchParams.append("extendEvents", extendEvents.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error(`Failed to fetch transaction by id: ${id}`);
    }
    return response.json();
  }

  async getAverageGasFees(network: string) {
    const response = await fetch(
      `${BASE_URL}/${network}/transactions/avg-gas-fees`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch average gas fees");
    }
    return response.json();
  }

  async getWalletById(network: string, id: string): Promise<any> {
    const response = await fetch(`${BASE_URL}/${network}/wallets/${id}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch wallet by id: ${id}`);
    }
    return response.json();
  }

  async getWalletTransactions(
    network: string,
    id: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<any>> {
    const url = new URL(`${BASE_URL}/${network}/wallets/${id}/transactions`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error(`Failed to fetch transactions for wallet: ${id}`);
    }
    return response.json();
  }

  async getWalletBalances(
    network: string,
    id: string,
    options?: PaginationOptions
  ): Promise<any> {
    const url = new URL(`${BASE_URL}/${network}/wallets/${id}/balances`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch balances for wallet: ${id}`);
    }
    return response.json();
  }

  async getWalletIncomingTransfers(
    network: string,
    id: string,
    options?: PaginationOptions
  ): Promise<any> {
    const url = new URL(`${BASE_URL}/${network}/wallets/${id}/transfers`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch incoming transfers for wallet: ${id}`);
    }
    return response.json();
  }

  async getContractList(
    network: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<any>> {
    const url = new URL(`${BASE_URL}/${network}/contracts`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch contract list");
    }
    return response.json();
  }

  async getContractByAddress(network: string, id: string): Promise<any> {
    const response = await fetch(`${BASE_URL}/${network}/contracts/${id}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch contract by id: ${id}`);
    }
    return response.json();
  }

  async getTokenList(
    network: string,
    orderBy?: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<any>> {
    const url = new URL(`${BASE_URL}/${network}/tokens`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    if (orderBy !== undefined) {
      url.searchParams.append("orderBy", orderBy.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch token list");
    }
    return response.json();
  }

  async getTokenHoldersList(
    network: string,
    id: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<any>> {
    const url = new URL(`${BASE_URL}/${network}/tokens/${id}/holders`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch token holders list");
    }
    return response.json();
  }

  async getSearchList(
    network: string,
    id: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<any>> {
    const url = new URL(`${BASE_URL}/${network}/search`);
    url.searchParams.append("id", id.toString());
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch search results");
    }
    return response.json();
  }

  async verifyContract(
    network: string,
    id: string,
    ecmaScriptVersion: string,
    dependencies: any[],
    abi: any[],
    entryFile: string,
    zipFile: File
  ) {
    const url = new URL(`${BASE_URL}/${network}/contracts/${id}/verify`);

    const formData = new FormData();
    formData.append("dependencies", JSON.stringify(dependencies));
    formData.append("abi", JSON.stringify(abi));
    formData.append("entryFile", entryFile);
    formData.append("ecmaScriptVersion", ecmaScriptVersion);
    formData.append("files", zipFile);

    const response = await fetch(url.toString(), {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Failed to verify contract: ${response.statusText}`);
    }

    const result = await response.json();
    return result;
  }

  async getTransactionEvents(
    network: string,
    id: string,
    types?: string[],
    includeContracts?: boolean,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<any>> {
    const url = new URL(`${BASE_URL}/${network}/transactions/${id}/events`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    if (types !== undefined) {
      url.searchParams.append("types", types.join(","));
    }
    if (includeContracts !== undefined) {
      url.searchParams.append("includeContracts", `${includeContracts}`);
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch transaction events list");
    }
    return response.json();
  }

  async getContractEvents(
    network: string,
    id: string,
    types?: string[],
    includeContracts?: boolean,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<any>> {
    const url = new URL(`${BASE_URL}/${network}/contracts/${id}/events`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    if (types !== undefined) {
      url.searchParams.append("types", types.join(","));
    }
    if (includeContracts !== undefined) {
      url.searchParams.append("includeContracts", `${includeContracts}`);
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch contract events list");
    }
    return response.json();
  }

  async getNetworkList(): Promise<any[]> {
    const url = new URL(`${BASE_URL}/networks`);
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch network list");
    }
    return response.json();
  }
}
