import { useEffect, useRef, useState } from "react";

import * as monaco from "monaco-editor";
import clarityLang from "../monaco-langs/ClarityLang";

import { Toggle } from "@/components/ui/toggle";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

monaco.languages.register({ id: "clarity" });
monaco.languages.setMonarchTokensProvider("clarity", clarityLang as any);

const CodeEditor = ({ language, code, schema }: any) => {
  const [lastEditor, setLastEditor] = useState<any>(null);
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      lastEditor?.dispose();

      const editor = monaco.editor.create(editorRef.current, {
        value: code,
        language: language,
        theme: "vs-dark",
        readOnly: true,
        automaticLayout: true,
        codeLens: false,
        minimap: { enabled: false },
        stickyScroll: {
          enabled: false,
        },
        fontSize: 14,
      });

      setLastEditor(editor);

      // Auto format the code on load
      editor.getAction("editor.action.formatDocument")?.run();

      return () => {
        editor.dispose();
      };
    }
  }, [language, code]);

  return (
    <div
      ref={editorRef}
      className="overflow-hidden rounded-lg touch-none"
      style={{ width: "100%", height: "400px" }}
      key="edt"
    />
  );
};

export type SourceCodeProps = {
  abi?: string;
  wast?: string;
  bytecode: string;
};

export function SourceCodeCard({ wast, bytecode, abi }: SourceCodeProps) {
  const [type, setType] = useState<string>("bytecode");
  return (
    <Card className="overflow-hidden w-full">
      <CardHeader>
        <CardTitle className="flex justify-between">
          Code
          <Select value={type} onValueChange={setType}>
            <SelectTrigger className="w-[180px]">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Type</SelectLabel>
                {wast && <SelectItem value="wast">WAST</SelectItem>}
                <SelectItem value="bytecode">Bytecode</SelectItem>
                {abi && <SelectItem value="abi">ABI</SelectItem>}
              </SelectGroup>
            </SelectContent>
          </Select>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2 grow">
        <CodeEditor
          language={
            type === "abi" ? "json" : type === "wast" ? "javascript" : "text"
          }
          code={type === "abi" ? abi : type === "wast" ? wast : bytecode}
        />
      </CardContent>
    </Card>
  );
}
