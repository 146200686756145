import React, { useState, useRef, useCallback, useEffect } from "react";
import { SearchIcon } from "lucide-react";
import { Combobox } from "@headlessui/react";
import { AddressVerificator } from "@btc-vision/transaction";
import { useNavigate } from "react-router-dom";

import { cn } from "@/lib/utils";
import { getBTCNetwork } from "@/lib/getBTCNetwork";
import { useSearch } from "@/hooks/useSearch";
import { SearchItem } from "@/components/SearchItem";

export function Search({ className }: { className?: string }) {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [result, setResult] = useState<any>(null);

  function handleSearch(address: string) {
    if (!address) {
      setResult(null);
    }
    if (/^\d+$/.test(address)) {
      return setResult({
        type: "block",
        height: address,
      });
    }

    if (address.startsWith("0x")) {
      return setResult({
        type: "wallet",
        address: address,
      });
    }

    if (/^[a-fA-F0-9]{64}$/.test(address)) {
      return setResult({
        type: "transaction",
        idOrHash: address,
      });
    }

    // in this order so tx isnt counted as p2pk
    // todo: get network
    const network = getBTCNetwork("regtest");
    const addressType = AddressVerificator.detectAddressType(address, network);

    if (addressType !== null) {
      return setResult({
        type: "wallet",
        address: address,
      });
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      handleSearch(query);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  const onChange = useCallback(() => {
    const value = (ref?.current as any)?.value as any;
    setQuery(value);
  }, []);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch(query);
      if (!result) {
        return;
      }
      switch (result.type) {
        case "block":
          navigate(`/blocks/${result.height}`);
          return;
        case "wallet":
          navigate(`/accounts/${result.address}`);
          return;
        case "transaction":
        default:
          navigate(`/transactions/${result.idOrHash}`);
          return;
      }
    }
  };

  return (
    <Combobox value={selected} onChange={setSelected}>
      <div className="relative">
        <div
          className={cn(
            "flex items-center h-10 w-full overflow-hidden rounded-md border border-input bg-background text-sm ring-offset-background focus-within:shadow disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
        >
          <SearchIcon className="mx-3 h-5 w-5" />
          <Combobox.Input
            className="flex grow bg-background py-2 pr-3 focus-visible:outline-none placeholder:text-muted-foreground"
            placeholder="Search by Address / Tx ID / Block Height"
            onChange={onChange}
            onPaste={onChange}
            onCut={onChange}
            onKeyDown={handleKeyDown}
            displayValue={(framework: any) => framework?.label}
            ref={ref}
          />
        </div>
        {query !== "" && result && (
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm overflow-hidden">
            <Combobox.Option
              value={result.type}
              className={({ active }: any) =>
                cn("relative cursor-default select-none")
              }
            >
              <SearchItem entity={result} />
            </Combobox.Option>
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
