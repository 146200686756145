import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import {
  useTransactions,
  useAccountTransactions,
  TransactionType,
} from "@/hooks";
import { TransactionRow } from "@/components/TransactionRow";
import { DataTablePagination } from "@/components/DataTablePagination";
import { SkeletonRow } from "@/components/SkeletonRow";

export type TransactionListProps = {
  types?: TransactionType[];
  blockHash?: string;
  principal?: string;
  paginate?: boolean;
  compact?: boolean;
  addresses?: string[];
};

function InnerTransactionList({
  transactions,
  compact,
  paginate,
  loading,
  total,
  page,
  pageSize,
  changePageSize,
  goToPage,
  prevPage,
  nextPage,
  address,
  hasNextPage,
}: any) {
  return (
    <div>
      {!loading && transactions?.length === 0 && (
        <div className="flex w-full p-4 text-center items-center justify-center">
          No Results Found
        </div>
      )}
      {(loading || transactions?.length > 0) && (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Transaction Info</TableHead>
              <TableHead className="hidden sm:table-cell">Tx ID</TableHead>
              {!compact && (
                <TableHead className="hidden lg:table-cell">
                  Total OpNet Fees
                </TableHead>
              )}
              {!compact && (
                <TableHead className="hidden lg:table-cell">Gas Used</TableHead>
              )}
              {!compact && (
                <TableHead className="hidden md:table-cell">Block</TableHead>
              )}
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {loading
              ? Array.from({ length: 10 }).map((_, i) => (
                  <SkeletonRow
                    icon={true}
                    title={true}
                    subtitle={true}
                    date={true}
                    otherCols={compact ? 1 : 3}
                    key={i}
                  />
                ))
              : null}
            {!loading &&
              transactions.map((transaction: any) => (
                <TransactionRow
                  transaction={transaction}
                  compact={compact}
                  key={transaction?.id}
                  address={address}
                />
              ))}
          </TableBody>
        </Table>
      )}
      {paginate && (
        <div className="border-t bg-muted/50 font-medium px-3 py-2">
          <DataTablePagination
            loading={loading}
            total={total}
            page={page}
            pageSize={pageSize}
            setPageSize={changePageSize}
            setPage={goToPage}
            prevPage={prevPage}
            nextPage={nextPage}
            hasNextPage={hasNextPage}
          />
        </div>
      )}
    </div>
  );
}

function AccountTransactionList({
  types,
  blockHash,
  principal,
  paginate,
  compact,
}: TransactionListProps) {
  const {
    transactions,
    total,
    loading,
    goToPage,
    page,
    pageSize,
    changePageSize,
    prevPage,
    nextPage,
    hasNextPage,
  } = useAccountTransactions({
    address: principal!,
    blockHeight: blockHash,
  });

  return (
    <InnerTransactionList
      transactions={transactions}
      total={total}
      loading={loading}
      page={page}
      pageSize={pageSize}
      changePageSize={changePageSize}
      goToPage={goToPage}
      prevPage={prevPage}
      nextPage={nextPage}
      paginate={paginate}
      compact={compact}
      address={principal!}
      hasNextPage={hasNextPage}
    />
  );
}

function GeneralTransactionList({
  types,
  blockHash,
  principal,
  paginate,
  compact,
}: TransactionListProps) {
  const {
    transactions,
    total,
    loading,
    goToPage,
    page,
    pageSize,
    changePageSize,
    prevPage,
    nextPage,
    hasNextPage,
  } = useTransactions({
    types,
    blockHash,
  });

  return (
    <InnerTransactionList
      transactions={transactions}
      total={total}
      loading={loading}
      page={page}
      pageSize={pageSize}
      changePageSize={changePageSize}
      goToPage={goToPage}
      prevPage={prevPage}
      nextPage={nextPage}
      paginate={paginate}
      compact={compact}
      hasNextPage={hasNextPage}
    />
  );
}

export function TransactionList(props: TransactionListProps) {
  if (props.principal) {
    return <AccountTransactionList {...props} />;
  }
  return <GeneralTransactionList {...props} />;
}
