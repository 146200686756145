import { useContext, useMemo, useState } from "react";
import { StacksApiContext } from "../context";

export function useNetworks() {
  const [networks, setNetworks] = useState<any[]>([]);
  const { opnetApi } = useContext(StacksApiContext)!;

  useMemo(async () => {
    const networksList = await opnetApi.getNetworkList();
    setNetworks(networksList);
  }, [opnetApi]);

  return networks;
}
