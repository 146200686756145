import * as React from "react";
import { Wallet2 } from "lucide-react";
import { useRecoilState } from "recoil";

import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { WalletConnect } from "@/services";
import {
  NetworkState,
  ShowWalletConnectState,
  WalletConnectState,
} from "@/state";
import OpLogo from "@/assets/op_logo.png";
import UnisatLogo from "@/assets/unisat_logo.jpg";

function WalletSelector({
  className,
  close = () => {},
}: React.ComponentProps<any>) {
  const [network, _] = useRecoilState(NetworkState);
  const [error, setError] = React.useState<string | undefined>();

  // todo: add arrow
  // todo: add no wallet info

  return (
    <div className="flex flex-col gap-4">
      {error && <div className="text-destructive text-md">{error}</div>}
      {window.opnet && (
        <Button
          variant="ghost"
          className="flex items-center p-4 py-6 rounded-lg border gap-2"
          onClick={async () => {
            try {
              await WalletConnect.instance.connect("opwallet", network);
              close();
            } catch (err: any) {
              setError(err?.message ?? err?.toString() ?? "An error occurred");
            }
          }}
        >
          <img src={OpLogo} className="w-7 h-7 rounded-md" />
          <div className="font-bold text-md">OP_Wallet</div>
        </Button>
      )}
      {window.unisat && !window.unisat.web3 && (
        <Button
          variant="ghost"
          className="flex items-center p-4 py-6 rounded-lg border gap-2"
          onClick={async () => {
            try {
              await WalletConnect.instance.connect("unisat", network);
              close();
            } catch (err: any) {
              setError(err?.message ?? err?.toString() ?? "An error occurred");
            }
          }}
        >
          <img src={UnisatLogo} className="w-6 h-6 rounded-md" />
          <div className="font-bold text-md">Unisat</div>
        </Button>
      )}
    </div>
  );
}

export function ConnectWalletButton() {
  const [connected, _] = useRecoilState(WalletConnectState);
  const [open, setOpen] = useRecoilState(ShowWalletConnectState);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button
            variant={connected ? "default" : "outline"}
            size="icon"
            className="hidden md:flex"
          >
            <Wallet2 />
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Connect a Wallet</DialogTitle>
          </DialogHeader>
          <WalletSelector close={() => setOpen(false)} />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <Button
          variant={connected ? "default" : "outline"}
          size="icon"
          className="hidden md:flex"
        >
          <Wallet2 />
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader className="text-left">
          <DrawerTitle>Connect a Wallet</DrawerTitle>
        </DrawerHeader>
        <WalletSelector className="px-4" close={() => setOpen(false)} />
        <DrawerFooter className="pt-2">
          <DrawerClose asChild>
            <Button variant="outline">Cancel</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
