import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { StacksApiContext } from "../context";
import { NetworkState } from "../state";

import { useApi } from "./useApi";
import { usePagination, PaginationOptions } from "./usePagination";

export interface ContractEventsProps extends PaginationOptions {
  contractId: string;
  types?: string[];
  includeContracts?: boolean;
}

export function useContractEvents(props: ContractEventsProps) {
  const { opnetApi } = useContext(StacksApiContext)!;
  const network = useRecoilValue(NetworkState);
  const { page, pageSize, ...paginationFns } = usePagination(props);

  const { data, loading, error } = useApi(
    opnetApi.getContractEvents.bind(opnetApi),
    [
      network,
      props.contractId,
      props.types,
      props.includeContracts,
      {
        limit: pageSize,
        cursor: page - 1,
      },
    ]
  );

  return {
    events: data?.results || [],
    hasNextPage: data?.hasNextPage ?? true,
    loading,
    error,
    page,
    pageSize,
    ...paginationFns,
  };
}
