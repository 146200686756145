import { useMemo } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { CopyableText } from "@/components/CopyableText";
import { truncateInMiddle } from "@/lib";

export function ArrayView({ array }: { array: any[] }) {
  return (
    <ScrollArea className="flex h-20 font-medium flex-1">
      {array.map((item, i) => (
        <>
          <div key={i} className="flex justify-between flex-row py-2">
            <div>{i}</div>
            <CopyableText text={item} displayText={item} />
          </div>
          {i < array.length - 1 && <Separator className="my-2" />}
        </>
      ))}
    </ScrollArea>
  );
}
