import { useMemo } from "react";
import {
  Bitcoin,
  Receipt,
  FunctionSquare,
  Coins,
  Code,
  ArrowRight,
  LucideIcon,
  AlertTriangle,
  Unlock,
  Vault,
} from "lucide-react";
import { Link } from "react-router-dom";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";

import {
  truncateInMiddle,
  createTitleForTransaction,
  cn,
  formatNumber,
  satsToBTC,
} from "@/lib";
import { CenteredRowCell } from "@/components/CenteredRowCell";
import { CommonRow } from "@/components/CommonRow";
import { BlockDisplayCell } from "@/components/BlockDisplayCell";
import { CopyableText } from "@/components/CopyableText";
import { BTCPriceConversion } from "@/components/BTCPriceConversion";

export type TransactionRowProps = {
  transaction: any;
  livePrice?: boolean;
  compact?: boolean;
  className?: string;
  address?: string;
};

export function TransactionRow({
  transaction,
  compact = false,
  livePrice = true,
  address,
  className,
}: TransactionRowProps) {
  const AddressableTo = useMemo(() => {
    switch (transaction.type) {
      case "coinbase":
        const outputs = new Set(transaction.outputAddresses || []);
        return (
          <Button
            variant="link"
            className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
            asChild
          >
            {outputs.size === 1 ? (
              <Link to={`/accounts/${transaction.outputAddresses[0]}`}>
                Mined By:{" "}
                {truncateInMiddle(transaction.outputAddresses[0] ?? "", 10)}
              </Link>
            ) : (
              <>Mined By {outputs.size ?? 0} Addresses</>
            )}
          </Button>
        );
      case "deployment":
        return (
          <Button
            variant="link"
            className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
            asChild
          >
            <Link to={`/accounts/${transaction.deployerAddress}`}>
              Deployer: {truncateInMiddle(transaction.deployerAddress, 10)}
            </Link>
          </Button>
        );
      case "interaction":
        return (
          <div className="flex flex-row items-center gap-1 text-xs font-medium text-muted-foreground">
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              asChild
            >
              <Link to={`/accounts/${transaction.caller}`}>
                {truncateInMiddle(transaction.caller, 10)}
              </Link>
            </Button>
            <ArrowRight className="h-3 w-3" />
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              asChild
            >
              <Link to={`/accounts/${transaction.contractAddress}`}>
                {truncateInMiddle(transaction.contractAddress, 10)}
              </Link>
            </Button>
          </div>
        );
      case "wrap":
        return (
          <div className="flex flex-row items-center gap-1 text-xs font-medium text-muted-foreground">
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              asChild
            >
              <Link to={`/accounts/${transaction?.depositAddress}`}>
                {truncateInMiddle(transaction?.depositAddress ?? "", 10)}
              </Link>
            </Button>
            <ArrowRight className="h-3 w-3" />
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              asChild
            >
              <Link to={`/accounts/${transaction?.vaultAddress}`}>
                Vault {truncateInMiddle(transaction?.vaultAddress ?? "", 10)}
              </Link>
            </Button>
          </div>
        );
      case "unwrap":
        return (
          <div className="flex flex-row items-center gap-1 text-xs font-medium text-muted-foreground">
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              asChild
            >
              <Link to={`/accounts/${transaction?.unwrapVaultAddress}`}>
                Vault{" "}
                {truncateInMiddle(transaction?.unwrapVaultAddress ?? "", 10)}
              </Link>
            </Button>
            <ArrowRight className="h-3 w-3" />
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              asChild
            >
              <Link to={`/accounts/${transaction?.caller}`}>
                {truncateInMiddle(transaction?.caller ?? "", 10)}
              </Link>
            </Button>
          </div>
        );
      case "generic":
      default:
        return (
          <div className="flex flex-row items-center gap-1 text-xs font-medium text-muted-foreground">
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              asChild
            >
              {transaction.inputs?.length === 1 ? (
                <Link to={`/accounts/${transaction?.inputAddresses[0]}`}>
                  {truncateInMiddle(transaction?.inputAddresses[0] ?? "", 10)}
                </Link>
              ) : (
                <>{transaction.inputs?.length} Inputs</>
              )}
            </Button>
            <ArrowRight className="h-3 w-3" />
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              asChild
            >
              {transaction.outputs?.length === 1 ? (
                <Link to={`/accounts/${transaction?.outputAddresses[0]}`}>
                  {truncateInMiddle(transaction?.outputAddresses[0] ?? "", 10)}
                </Link>
              ) : (
                <>{transaction.outputs?.length} Outputs</>
              )}
            </Button>
          </div>
        );
    }
  }, [transaction]);
  const Icon: LucideIcon = useMemo(() => {
    switch (transaction.type) {
      case "interaction":
        return FunctionSquare;
      case "coinbase":
        return Coins;
      case "deployment":
        return Code;
      case "wrap":
        return Vault;
      case "unwrap":
        return Unlock;
      default:
        return Receipt;
    }
  }, [transaction]);
  const title = useMemo(() => {
    const success = !transaction.revert;
    const price = satsToBTC(BigInt(transaction.inputValue) ?? 0n);
    return (
      <div
        className={cn(
          "flex flex-row items-center gap-1 text-ellipsis overflow-hidden",
          !success && "text-destructive"
        )}
      >
        {!success && <AlertTriangle className="h-4 w-4" />}
        {createTitleForTransaction(
          transaction,
          address,
          transaction?.isSender ?? false
        )}
        {/* fix price conversion for when "address" is set*/}
        {transaction.type === "generic" && (
          <div className="text-xs font-medium text-muted-foreground">
            <BTCPriceConversion
              amount={price}
              date={!livePrice && transaction.blockTime}
            />
          </div>
        )}
      </div>
    );
  }, [transaction]);
  return (
    <CommonRow
      icon={Icon}
      title={title}
      subtitle={AddressableTo}
      date={transaction.blockTime}
      className={className}
      href={`/transactions/${transaction?.id}`}
    >
      <CenteredRowCell className="hidden sm:table-cell">
        <Badge variant="outline" className="font-medium gap-1 monospaced">
          <CopyableText
            text={transaction.id}
            displayText={truncateInMiddle(transaction.id, 10)}
          />
        </Badge>
      </CenteredRowCell>
      {!compact && (
        <>
          <CenteredRowCell className="hidden lg:table-cell">
            <div className="text-muted-foreground text-sm">
              {formatNumber(satsToBTC(BigInt(transaction.burnedBitcoin)))} BTC
            </div>
          </CenteredRowCell>
          <CenteredRowCell className="hidden lg:table-cell">
            <div className="text-muted-foreground text-sm">
              {formatNumber(satsToBTC(BigInt(transaction.gasSats)))} BTC
            </div>
          </CenteredRowCell>
          <BlockDisplayCell
            color="#FF7900"
            icon={Bitcoin}
            height={transaction.blockHeight}
          />
        </>
      )}
    </CommonRow>
  );
}
