import { DollarSign, Code, PersonStanding } from "lucide-react";
import { Decimal } from "decimal.js";

import { CenteredRowCell } from "@/components/CenteredRowCell";
import { Badge } from "@/components/ui/badge";
import { truncateInMiddle } from "@/lib";

import { CommonRow } from "@/components/CommonRow";
import { CopyableText } from "@/components/CopyableText";

export type TokenHolderRowProps = {
  holder: any;
  contract: any;
  compact?: boolean;
  className?: string;
};

export function TokenHolderRow({
  holder,
  contract,
  className,
}: TokenHolderRowProps) {
  const decimals = contract.op20Metadata?.decimals ?? 8;
  const amount = new Decimal(holder.balance)
    .dividedBy((10n ** BigInt(contract.op20Metadata?.decimals || 0)).toString())
    .toNumber();
  const percentOfTotalSupply = new Decimal(holder.balance)
    .dividedBy(contract.op20Metadata?.totalSupply ?? 1)
    .times(100)
    .toNumber()
    .toFixed(2);
  return (
    <CommonRow
      icon={DollarSign}
      title={`${amount?.toLocaleString("en-US", { maximumSignificantDigits: 8 })} ${contract.op20Metadata?.symbol}`}
      className={className}
      href={`/accounts/${holder.address}`}
    >
      <CenteredRowCell>
        <Badge variant="outline" className="font-medium gap-1 monospaced">
          {holder?.isContract ? (
            <Code className="h-3 w-3" />
          ) : (
            <PersonStanding className="h-3 w-3" />
          )}
          <CopyableText
            text={holder.address}
            displayText={holder.address && truncateInMiddle(holder.address, 10)}
          />
        </Badge>
      </CenteredRowCell>
      <CenteredRowCell>{percentOfTotalSupply}%</CenteredRowCell>
    </CommonRow>
  );
}
